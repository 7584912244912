import {
  type Delta,
  type DeltaAttributes,
  type DeltaInsertAttributes,
  type DeltaOptions, DiagnosticSeverity,
  type Editor,
  type IAvvAny,
  type IAvvElement,
  type MountedEditor, runDiagnostics,
  type SelectionRange
} from '@avvoka/editor'
import {
  AttributeMap,
  DeltaParser,
  extractLineFormats,
  getLineFormat,
  hasAttributes,
  HtmlParser,
  isLine,
  Scope,
  blotList,
  DeltaFormatsBuilder
} from '@avvoka/editor'
import {
  BitArray,
  clone,
  orderObjectKeys,
  Source,
  TextTools
} from '@avvoka/shared'
import type { StoreWithStyles } from '.'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'
import { useDocumentStore } from '@stores/generic/document.store'
import {
  StoreMode,
  useDefaultDocxSettings,
  type DocxSettings
} from '@stores/utils'
import {toRaw} from 'vue'

declare module '@avvoka/editor' {
  interface Editor {
    getResolvedDocxStyles(delta: Delta): Delta
    getStyleStore():
      | StoreWithStyles
      | {
          styles: DocxSettings
          docxSettings: DocxSettings
          defaultStyle: Backend.Models.TemplateVersion.Style & {
            key: string
          }
        }
  }
}

export const extractAllStylesAttributes = (
  style: Backend.Models.TemplateVersion.Style,
  linesOnly = true,
  memo: DeltaAttributes = {}
) => {
  if (style == null) return {}
  if (style.parent && typeof style.parent === 'object') {
    // Temporarily disabled as styles already include their parent values
    // extractAllStylesAttributes(style.parent, linesOnly, memo)
  }

  const rawAttributes = toRaw(style.definition) as DeltaAttributes
  const copied = clone(
    linesOnly ? extractLineFormats(rawAttributes) : rawAttributes
  )
  for (const key in copied) {
    memo[key] = copied[key]
  }

  return memo
}

// We want to apply the styles from the format to delta
export const addStylesToDelta = (
  delta: Delta,
  storeFormats: Backend.Models.TemplateVersion.Styles
): Delta => {
  let lastValidNumbering: DeltaInsertAttributes[string] | null = null
  for (let i = 0; i < delta.ops.length; i++) {
    const op = delta.ops[i]
    if (isLine(op) && hasAttributes(op)) {
      const lineFormat = getLineFormat(op.attributes)
      if (
        lineFormat &&
        op.attributes[lineFormat]['data-avv-style'] !== undefined
      ) {
        const styleName = op.attributes[lineFormat]['data-avv-style'] as string
        const styleWrapper = storeFormats[styleName]
        if (styleWrapper?.definition) {
          let styleAttributes = extractAllStylesAttributes(
            styleWrapper
          ) as DeltaInsertAttributes

          const hasNumbering =
            styleAttributes.numbered !== undefined ||
            op.attributes.numbered !== undefined

          // Book Note 1: When numbering is not applied in html, but is listed in styles and line format has num-level=0, then numbering is disabled.
          if (
            lineFormat &&
            op.attributes[lineFormat]['num-level'] === '0' &&
            op.attributes.numbered === undefined
          ) {
            delete styleAttributes.numbered
          }

          if (
            styleAttributes.numbered?.['data-section-id']
          )
            styleAttributes.numbered['data-numbered-id'] =
              TextTools.randomText(6)
          const order = Object.keys(op.attributes)

          // We should not restrict styles to 'blocks' only, as we can have styles for avvTocEntry too
          let styleLineFormat = getLineFormat(styleAttributes)
          if (styleLineFormat !== lineFormat && styleLineFormat != null) {
            styleAttributes[lineFormat] = styleAttributes[styleLineFormat]
            delete styleAttributes[styleLineFormat]
            styleLineFormat = lineFormat
          }

          const insertKeys = Object.keys(styleAttributes).filter(
            (key) => !order.includes(key)
          )

          const blockIndex = order.indexOf(lineFormat)
          order.splice(blockIndex, 0, ...insertKeys)

          transformAttributes(op.attributes)
          transformAttributes(styleAttributes)

          if(hasNumbering) {
            // Skip block styles for numbering
            styleAttributes = AttributeMap.exclude(
              { block: {} },
              styleAttributes,
              true,
              true
            ) as DeltaInsertAttributes
          }

          op.attributes = AttributeMap.compose(styleAttributes, op.attributes)
          op.attributes = orderObjectKeys(op.attributes, order)

          if (hasNumbering) {
            // If we have negative text-indent and numbering is on. We need to remove text-indent
            // Keep attribute in case the style applied does not include that attribute
            if (
              op.attributes[lineFormat]['data-avv-text-indent'] &&
              styleAttributes[lineFormat]?.['data-avv-text-indent']
            ) {
              // const textIndent = op.attributes.block[
              //   'data-avv-text-indent'
              // ] as string
              // if (textIndent.startsWith('-')) {
              delete op.attributes[lineFormat]['data-avv-text-indent']
              // }
            }
          }

          detransformAttributes(op.attributes)
          detransformAttributes(styleAttributes)

          // In case of ToC delete all containers but dummy
          if (lineFormat === 'avvTocEntry') {
            const containers = blotList.filter((b) => b.scope & Scope.Container)
            for (const blot of containers) {
              if (blot.blotName !== 'avvToc' && blot.blotName !== 'dummy')
                delete op.attributes[blot.blotName]
            }
          }

          if (
            op.attributes.numbered &&
            (op.attributes.numbered['data-section-id'] === undefined ||
              op.attributes.numbered['data-numbered-id'] === undefined)
          ) {
            const maskVersion = +(op.attributes.numbered['data-mask-version'] ?? lastValidNumbering?.['data-mask-version'] ?? 1)
            // Inherit section id from numbering above
            const numbered = DeltaFormatsBuilder.create()
              .numbered({
                numberedId:
                  (op.attributes.numbered['data-numbered-id'] as string) ??
                  (lastValidNumbering?.['data-numbered-id'] as string),
                sectionId:
                  (op.attributes.numbered['data-section-id'] as string) ??
                  (lastValidNumbering?.['data-section-id'] as string),
                mask:
                  (op.attributes.numbered['data-mask-pattern'] as string) ??
                  (lastValidNumbering?.['data-mask-pattern'] as string),
                [maskVersion <= 1 ? 'level' : 'dataLevel']: (op.attributes.numbered[maskVersion <= 1 ? 'level' : 'data-level'] as string) ?? (lastValidNumbering?.['data-level'] as string)
              })
              .build()
            op.attributes.numbered = AttributeMap.compose(
              op.attributes.numbered as DeltaInsertAttributes,
              numbered
            )
          }
        }
      }
    }

    if (hasAttributes(op) && op.attributes.numbered) {
      lastValidNumbering = op.attributes
        .numbered as DeltaInsertAttributes[string]
    }
  }
  return delta
}

const transformAttributes = (attributes: DeltaAttributes) => {
  if (attributes.numbered) {
    if (typeof attributes.numbered['data-styles'] === 'string') {
      attributes.numbered['data-styles'] = (attributes.numbered['data-styles'])
        .split(' ')
        .reduce<DeltaInsertAttributes>(
          (acc, style) => {
            acc[style] = {}
            return acc;
          }, {}
        )
    }
  }
  return attributes
}

const detransformAttributes = (attributes: DeltaAttributes) => {
  if (attributes.numbered) {
    if (attributes.numbered['data-styles']) {
      attributes.numbered['data-styles'] = Object.keys(
        attributes.numbered['data-styles'] as DeltaInsertAttributes
      ).join(' ')
    }
  }
  return attributes
}

export const removeStylesFromDelta = (
  delta: Delta,
  storeFormats: Backend.Models.TemplateVersion.Styles
): Delta => {
  for (const op of delta.ops) {
    if (isLine(op) && hasAttributes(op)) {
      const lineFormat = getLineFormat(op.attributes)
      if (
        lineFormat &&
        op.attributes[lineFormat]['data-avv-style'] !== undefined
      ) {
        const styleName = op.attributes[lineFormat]['data-avv-style'] as string
        const styleWrapper = storeFormats[styleName]
        if (styleWrapper?.definition) {
          const styleAttributes = extractAllStylesAttributes(styleWrapper)

          transformAttributes(styleAttributes)
          transformAttributes(op.attributes)

          // We should not restrict styles to 'blocks' only, as we can have styles for avvTocEntry too
          let styleLineFormat = getLineFormat(styleAttributes)
          if (styleLineFormat !== lineFormat && styleLineFormat != null) {
            styleAttributes[lineFormat] = styleAttributes[styleLineFormat]
            delete styleAttributes[styleLineFormat]
            styleLineFormat = lineFormat
          }

          // Prevent excluding numbering level
          const numbering = clone(op.attributes.numbered)
          const block = clone(op.attributes[lineFormat])

          op.attributes = AttributeMap.exclude(
            styleAttributes,
            op.attributes,
            true,
            true
          ) as DeltaInsertAttributes

          if (styleLineFormat) {
            op.attributes[lineFormat] = AttributeMap.exclude(
              styleAttributes[styleLineFormat] as DeltaInsertAttributes,
              op.attributes[lineFormat] as DeltaInsertAttributes,
              false,
              true
            ) as DeltaInsertAttributes
          }

          if (numbering && op.attributes?.numbered) {
            const maskVersion = +(numbering['data-mask-version'] ?? 1)
            op.attributes.numbered[maskVersion <= 1 ? 'level' : 'data-level'] = numbering[maskVersion <= 1 ? 'level' : 'data-level']
            op.attributes.numbered['data-mask-pattern'] = numbering['data-mask-pattern']
            op.attributes.numbered['data-mask-version'] = numbering['data-mask-version']
          }

          // Add in required attributes for BE
          if (op.attributes[lineFormat]) {
            for (const attribute of [
              'data-avv-padding-left',
              'data-avv-text-indent'
            ]) {
              if (block?.[attribute])
                op.attributes[lineFormat][attribute] = block[attribute]
              else if (
                styleAttributes.block?.[attribute]
              )
                op.attributes[lineFormat][attribute] = (
                  styleAttributes.block as DeltaInsertAttributes
                )[attribute]
            }
          }

          detransformAttributes(op.attributes)
          detransformAttributes(styleAttributes)
        }
      }
    }
  }
  return delta
}

export const hookStylesForEditor = (editor: Editor) => {
  editor.getStyleStore = () => {
    const store = editor.options.styleStore
      ? editor.options.styleStore
      : editor.options.mode === 'document'
        ? useDocumentStore(getActivePinia())
        : useTemplateVersionStore(getActivePinia())

    if (store.hydrated || store.storeMode === StoreMode.NewData) {
      return store
    }
    const raw = useDefaultDocxSettings()
    const docxSettings = {
      formats: raw.formats,
      docxNamesByOrigin: raw.docx_names_by_origin,
      stylesRelations: raw.stylesRelations,
      inactiveFormats: raw.inactiveFormats,
      metadata: raw.metadata,
      version: raw.version,
      dataDocxRef: raw['data-docx-ref']
    }

    return {
      docxSettings,
      styles: docxSettings,
      defaultStyle: {
        key: ''
      }
    }
  }

  if (editor.options.mode === 'document') {
    editor.negotiation?.onContentChange.subscribe(() => {
      // Timeout to allow systems to update the rendered content
      setTimeout(() => {
        const diff = editor.readonlyDelta.diff(
          addStylesToDelta(
            editor.getDelta(),
            editor.getStyleStore().docxSettings.formats
          )
        )
        if (diff.length()) {
          console.log(
            "Styles are updating editor's delta based on negotiation content change.",
            diff
          )
          ;(editor as MountedEditor).update(
            diff,
            BitArray.fromSource(
              Source.USER,
              Source.DOCUMENT,
              Source.TRACKING_CHANGES,
              Source.STYLES
            )
          )
        }
      })
    })
  }

  const _load = editor.load.bind(editor as MountedEditor)
  editor.load = function (this: MountedEditor, data: string | Delta | IAvvAny) {
    let node =
      typeof data === 'string'
        ? HtmlParser.parse(data)
        : 'ops' in data
          ? DeltaParser.parse(data, this.options.mode)
          : (data as IAvvElement)

    const delta = addStylesToDelta(node.toDelta(), editor.getStyleStore().docxSettings.formats)
    node = DeltaParser.parse(delta, editor.options.mode)

    const diagnostics = runDiagnostics(node, editor.options.mode)
    if(diagnostics.length) {
      console.warn(`Diagnostics found issues (${diagnostics.length}).`)
      for (const diagnostic of diagnostics) {
        if(diagnostic.severity === DiagnosticSeverity.Error) {
          console.error(diagnostic.message, diagnostic)
        } else {
          console.warn(diagnostic.message, diagnostic)
        }
      }
    }

    return _load(node)
  }

  const _getDelta = editor.getDelta.bind(editor)
  editor.getDelta = (range?: SelectionRange, options?: DeltaOptions) => removeStylesFromDelta(
      _getDelta(range, options),
      editor.getStyleStore().docxSettings.formats
    )

  editor.getResolvedDocxStyles = (delta: Delta) => {
    return addStylesToDelta(delta, editor.getStyleStore().docxSettings.formats)
  }
}
